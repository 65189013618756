<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Neues Netz</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="create_network" class="form" method="post">
            <div class="form-wrap">
              <label for="title">Titel</label>
              <input v-model="network.title" class="form-input" type="text" name="title" id="title" />
            </div>

            <div class="form-wrap">
              <label for="description">Beschreibung</label>
              <textarea v-model="network.description" rows="6" class="form-input" name="description" id="description"></textarea>
            </div>

            <div class="content-wrap">
              <header class="content-header cf"><h3>Geografische Eingrenzung</h3></header>
              <div class="content-body">
                <MapView
                  :initialZoom="9"
                  :getPosition="false"
                  :adresssearch="true"
                  :showCircle="true"
                  :myMarker="true"
                  :clickRadius="true"
                  :editRadius="true"
                  :listView="true"
                  :selectedDevices.sync="network.devices"
                  @locationChange="updateLocation"
                  @radiusChange="updateRadius"
                ></MapView>
              </div>
            </div>

            <div v-if="errors.length > 0">
              {{ errors }}
            </div>

            <p style="text-align: right">
              <input type="submit" value="Netz erstellen ›" class="button button-ci button-round button-100" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";
import MapView from "@/components/MapView";

export default {
  name: "new_network",
  components: {
    AdminNav,
    MapView,
  },
  data() {
    return {
      errors: [],
      network: {
        title: "",
        description: "",
        devices: [],
        location: {
          lat: 48.80140101485355,
          lng: 9.799754985445963,
          radius: 10000,
        },
      },
      selectedDevices: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/users/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.user = response.data.user;
        });
    },
    updateLocation(value) {
      this.network.location.lng = value.lng;
      this.network.location.lat = value.lat;
    },
    updateRadius(value) {
      this.network.location.radius = value;
    },
    create_network: function (e) {
      e.preventDefault();

      axios
        .post(process.env.VUE_APP_BASE_API + "/admin/networks/", this.network, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$router.push("/admin/networks/");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    // this.get_data();
  },
};
</script>
